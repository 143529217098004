import { get } from "lodash";
import { RenderActions } from "../components";

export const customerOrdersColumns = [
  {
    Header: "Name",
    accessor: "customerId",
    minWidth: 100,
    renderCell: (params) => {
      return get(params, "customerData.name", "");
    },
  },
  {
    Header: "Status",
    accessor: "status",
    minWidth: 100,
  },
  {
    Header: "Order ID",
    accessor: "orderId",
    minWidth: 100,
  },
  {
    Header: "Discount",
    accessor: "discount",
    minWidth: 100,
    renderCell: (params) => {
      return `$${params}`;
    },
  },
  {
    Header: "Total",
    accessor: "total",
    minWidth: 150,
    renderCell: (params) => {
      return `$${params}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/customerOrders/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/customerOrders/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const inventoryColumns = [
  {
    Header: "Title",
    accessor: "title",
    minWidth: 100,
  },
  {
    Header: "Brand",
    accessor: "brand.title",
    minWidth: 100,
  },
  {
    Header: "Category",
    accessor: "category.title",
    minWidth: 100,
  },
  {
    Header: "Country",
    accessor: "country",
    minWidth: 100,
  },
  {
    Header: "Returnable",
    accessor: "returnable",
    minWidth: 150,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/inventory/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/inventory/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const invoiceColumns = [
  {
    Header: "Title",
    accessor: "title",
    minWidth: 100,
  },
  {
    Header: "Products",
    accessor: "products",
    minWidth: 100,
  },
  {
    Header: "Manufacturer Products",
    accessor: "manufacturerProducts",
    minWidth: 100,
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/invoices/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/invoices/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const storeOrdersColumns = [
  {
    Header: "Title",
    accessor: "title",
    minWidth: 100,
  },
  {
    Header: "Products",
    accessor: "products",
    minWidth: 100,
  },
  {
    Header: "Manufacturer Products",
    accessor: "manufacturerProducts",
    minWidth: 100,
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/storeOrders/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/storeOrders/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const profileColumns = [
  {
    Header: "Name",
    accessor: "registeredName",
    minWidth: 100,
  },
  {
    Header: "Active Orders",
    accessor: "activeOrders",
    minWidth: 100,
    renderCell: (params) => {
      return params?.length ? params.length : 0;
    },
  },
  {
    Header: "Completed Orders",
    accessor: "completedOrders",
    minWidth: 100,
    renderCell: (params) => {
      return params?.length ? params.length : 0;
    },
  },
  {
    Header: "GST In",
    accessor: "gstIn",
    minWidth: 100,
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/profile/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/profile/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const factoriesColumns = [
  {
    Header: "Code",
    accessor: "cardCode",
    minWidth: 150,
  },
  {
    Header: "Status",
    accessor: "status",
    minWidth: 100,
  },
  {
    Header: "Value",
    accessor: "value",
    minWidth: 100,
    renderCell: (params) => {
      return `$${params}`;
    },
  },
  {
    Header: "Expiry",
    accessor: "expiry",
    minWidth: 150,
    renderCell: (params) => {
      let formattedDate = new Date(params).toLocaleDateString("en-us", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return `${formattedDate}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/factories/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/factories/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];

export const adminColumns = [
  {
    Header: "Name",
    accessor: "name",
    minWidth: 150,
  },
  {
    Header: "Email",
    accessor: "email",
    minWidth: 150,
  },
  {
    Header: "Is Blocked",
    accessor: "isBlocked",
    minWidth: 100,
    renderCell: (params) => {
      return `${params ? "Yes" : "No"}`;
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    minWidth: 100,
    renderCell: (params) => {
      return (
        <div className="flex px-4">
          <RenderActions
            action="view"
            referenceId={get(params, "_id", "")}
            navigateTo={"/all-admins/view/{{id}}"}
          />
          <RenderActions
            action="edit"
            referenceId={get(params, "_id", "")}
            navigateTo={"/all-admins/edit/{{id}}"}
          />
          {/* <RenderActions
            action="block"
            referenceId={get(params, "id", "")}
            // deleteAction={blockRecruitersRequest}
          /> */}
          <RenderActions
            action="delete"
            referenceId={get(params, "id", "")}
            // deleteAction={deleteRecruiterRequest}
          />
        </div>
      );
    },
  },
];
