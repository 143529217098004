const sidebarContent = [
  {
    label: "Customer Orders",
    hasChildren: false,
    navigationRoute: "/customerOrders",
  },
  {
    label: "Inventory",
    hasChildren: false,
    navigationRoute: "/inventory",
  },
  {
    label: "Accounting",
    hasChildren: true,
    children: [
      {
        label: "Invoices",
        hasChildren: false,
        navigationRoute: "/invoices",
      },
    ],
  },
  {
    label: "Store Orders",
    hasChildren: false,
    navigationRoute: "/storeOrders",
  },
  {
    label: "Settings",
    hasChildren: true,
    children: [
      {
        label: "Access Control",
        hasChildren: false,
        navigationRoute: "/all-admins",
      },
      {
        label: "Factories",
        hasChildren: false,
        navigationRoute: "/factories",
      },
      {
        label: "Profile",
        hasChildren: false,
        navigationRoute: "/profile",
      },
    ],
  },
];
export default sidebarContent;
