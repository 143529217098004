import {
  Login,
  StoreOrders,
  AccessControl,
  AccessControlForm,
  CustomerOrders,
  CustomerOrdersForm,
  Inventory,
  InventoryForm,
  Invoices,
  InvoicesForm,
  StoreOrdersForm,
  Factories,
  FactoriesForm,
  Profile,
  ProfileForm,
} from "../screens";

export const routes = [
  {
    path: "/",
    Component: Login,
    isPublic: true,
    exact: true,
  },
  {
    path: "/customerOrders",
    Component: CustomerOrders,
    isPublic: false,
    exact: true,
  },
  {
    path: "/customerOrders/:action/:id",
    Component: CustomerOrdersForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/customerOrders/:action",
    Component: CustomerOrdersForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/inventory",
    Component: Inventory,
    isPublic: false,
    exact: true,
  },
  {
    path: "/inventory/:action/:id",
    Component: InventoryForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/inventory/:action",
    Component: InventoryForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/invoices",
    Component: Invoices,
    isPublic: false,
    exact: true,
  },
  {
    path: "/invoices/:action/:id",
    Component: InvoicesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/invoices/:action",
    Component: InvoicesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/storeOrders",
    Component: StoreOrders,
    isPublic: false,
    exact: true,
  },
  {
    path: "/storeOrders/:action/:id",
    Component: StoreOrdersForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/storeOrders/:action",
    Component: StoreOrdersForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/factories",
    Component: Factories,
    isPublic: false,
    exact: true,
  },
  {
    path: "/factories/:action/:id",
    Component: FactoriesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/factories/:action",
    Component: FactoriesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/profile",
    Component: Profile,
    isPublic: false,
    exact: true,
  },
  {
    path: "/profile/:action/:id",
    Component: ProfileForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/profile/:action",
    Component: ProfileForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins",
    Component: AccessControl,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins/:action/:id",
    Component: AccessControlForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins/:action",
    Component: AccessControlForm,
    isPublic: false,
    exact: true,
  },
];
