import React, { useState, useEffect } from "react";
import PeopleImg from "../../assets/PeopleImg.jpeg";
import LoginImg from "../../assets/LoginImg.jpeg";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { LOGIN_WITH_EMAIL } from "../../store/actions";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "puneet@gmail.com",
    password: "Test@123#",
  });

  const darkTheme = useSelector((state) => state.auth.darkTheme);

  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/customerOrders");
    }
  }, [isLoggedIn, navigate]);

  const onChangeHandler = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    dispatch(LOGIN_WITH_EMAIL.REQUEST(credentials, navigate));
  };

  const onForgotPassword = () => {
    console.log("On Forgot password clicked");
  };

  return (
    <div className={`flex`}>
      <div
        className={`transition duration-500 flex flex-col h-screen w-full	md:w-2/5 items-center px-16 min-w-max	${
          !darkTheme ? "bg-white" : "bg-dark text-white"
        }`}
      >
        <img src={LoginImg} className={`w-28 my-20`} alt="" />
        <h1 className={`text-4xl mb-16`}>Log In</h1>
        <Input
          type={"email"}
          header={"Email"}
          value={get(credentials, "email", "")}
          onChange={onChangeHandler}
          darkTheme={darkTheme}
          name="email"
        />
        <Input
          type={"password"}
          header={"Password"}
          value={get(credentials, "password", "")}
          onChange={onChangeHandler}
          darkTheme={darkTheme}
          name="password"
        />
        <span
          className={`w-11/12 text-end mb-4 cursor-pointer`}
          onClick={onForgotPassword}
        >
          Forgot Password?
        </span>
        <Button text={"Login"} onClick={onSubmit} />
      </div>
      <img className={`h-screen w-0 md:w-3/5`} src={PeopleImg} alt="" />
    </div>
  );
};

export default Login;
